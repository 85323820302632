<template>
  <ion-footer>
    <ion-toolbar>
      <div class="row mb-0 p-2">
        <div class="col-12 text-center">
          <p class="font-15 mb-1">กรุณาชำระเงินภายใน <strong class="color-blue-dark">{{ timerExp.min }}:{{ timerExp.sec }}</strong> นาที</p>
        </div>
        <div class="col-5">
          จำนวนที่เลือก<br>
          <strong class="color-blue-dark font-15">{{ carts.length }}</strong> ชุด (สูงสุด 20 ชุด)
        </div>
        <div class="col-7">
          <ion-nav-link router-direction="forward" :component="component"
                        class="btn btn-full mb-3 rounded-xl text-uppercase font-15 shadow-s bg-btn-default w-100">
            ตรวจสอบสลากฯ
          </ion-nav-link>
        </div>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {IonNavLink, IonFooter, IonToolbar} from "@ionic/vue";
import CartIndex from "@/views/cart/Index";

export default {
  name: 'CartFooter',
  components: {
    IonNavLink,
    IonFooter,
    IonToolbar
  },
  props: {
    carts: Array
  },
  data() {
    return {
      component: CartIndex,
      timerExp: {
        sec: "00",
        min: "00",
        hour: "00",
        day: "00"
      },
      loop_exp_cd: null,
    }
  },
  mounted() {
    this.timerExpCart();
    this.loop_exp_cd = setInterval(() => {
      this.timerExpCart()
    }, 1000)
  },
  methods: {
    async timerExpCart() {
      const exp = await this.storage.get('cart_exp');
      if (exp) {
        const now = this.moment(new Date()); //todays date
        const end = this.moment(exp); // another date
        const duration = this.moment.duration(now.diff(end));

        if (duration.days() >= 0 && duration.hours() >= 0 && duration.minutes() >= 0 && duration.seconds() >= 0) {
          this.timerExp = {
            sec: "00",
            min: "00",
            hour: "00",
            day: "00"
          };

          clearInterval(this.loop_exp_cd);
          // this.$swal.fire("ยกเลิกสลาก", "สลากยกเลิกเนื่องจากหมดเวลาในการยืนยันคำสั่งซื้อแล้ว กรุณาเลือกสินค้าใหม่และยืนยันคำสั่งซื้อภายใน 15 นาที", "error")
        } else if (duration.minutes() == 5 && duration.seconds() == 0) {
          // this.$swal.fire("เหลือเวลา 5 นาทีสุดท้าย", "ใกล้หมดเวลาแล้ว กรุณากดยืนยันคำสั่งซื้อเพื่อชำระเงินก่อนหมดเวลา ", "warning")
        } else {
          this.timerExp.sec = Math.abs(duration.seconds()) < 10 ? `0${Math.abs(duration.seconds())}` : Math.abs(duration.seconds());
          this.timerExp.min = Math.abs(duration.minutes()) < 10 ? `0${Math.abs(duration.minutes())}` : Math.abs(duration.minutes());
          this.timerExp.hour = Math.abs(duration.hours()) < 10 ? `0${Math.abs(duration.hours())}` : Math.abs(duration.hours());
          this.timerExp.day = Math.abs(duration.days()) < 10 ? `0${Math.abs(duration.days())}` : Math.abs(duration.days());
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
