<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="medium" @click="cancel">ยกเลิก</ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click="confirm" :strong="true">ชำระแล้ว</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div class="row">
      <div class="col-12" v-if="!loaded">
        <ion-thumbnail style="height: 30vh;width: auto;">
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-thumbnail>
      </div>
      <div class="col-12 text-center" v-else>
        <img :src="payments.mmn_payment.QRCode" class="text-center mb-3" style="height: 20vh;"><br/>
        <div class="alert alert-warning">
          QR Code จะถูกสร้างใหม่บิลต่อบิล ไม่สามารถใช้ซ้ำได้ กรุณาอย่าบันทึกรูป QR แล้วใช้ซ้ำ เนื่องจากจะทำให้เกิดปัญหาด้านการตรวจสอบและทำให้คำสั่งซื้อถูกยกเลิก
        </div>
<!--        <button class="btn bg-green-dark text-white rounded-2"><i class="fa fa-fw fa-download me-2"></i>บันทึก QR Code</button>-->
      </div>
    </div>
    <div class="col-12">
      <div class="divider"></div>
      <h3 class="text-center mt-4">วิธีชำระเงินผ่าน QR Code</h3>
      <ul>
        <li>เปิด Bank Application</li>
        <li>เลือกสแกน QR Code</li>
        <li>นำกล้องมาสแกนที่ QR Code หรือบันทึกรูป QR Code</li>
        <li>ชำระเงินตามขั้นตอน และกดที่ "ชำระแล้ว" ด้านบนขวามือ</li>
        <li>รอระบบตรวจสอบประมาณ 1-2 นาที</li>
        <li class="text-danger">QR Code จะถูกสร้างใหม่บิลต่อบิล ไม่สามารถใช้ซ้ำได้ กรุณาอย่าบันทึกรูป QR แล้วใช้ซ้ำ เนื่องจากจะทำให้เกิดปัญหาด้านการตรวจสอบและทำให้คำสั่งซื้อถูกยกเลิก</li>
      </ul>
    </div>
  </ion-content>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonContent,
  modalController,
  loadingController,
  IonSkeletonText,
  IonThumbnail,
  IonButtons,
  IonButton, alertController,
} from '@ionic/vue';

export default {
  name: 'QRCodeModal',
  components: {
    IonHeader,
    IonToolbar,
    IonContent,
    IonSkeletonText,
    IonThumbnail,
    IonButtons,
    IonButton,
  },
  props: {
    payments: Object
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 1000);
  },
  data() {
    return {
      loaded: false,
      loop_check: null,
      loading: null,
      loop: 0
    }
  },
  methods: {
    cancel() {
      return modalController.dismiss(null, 'cancel');
    },
    async confirm() {
      this.loading = await loadingController.create({
        message: 'กำลังตรวจสอบ อาจใช้เวลาประมาณ 1-2 นาที',
        spinner: 'circles',
        mode: 'ios'
      })

      await this.loading.present();

      this.loop_check = setInterval(() => {
        this.checkOrder()
        this.loop += 1;
      }, 10000);

      if (this.loop >= 10) {
        clearInterval(this.loop_check);
        const alert = await alertController.create({
          header: 'ขออภัย',
          subHeader: 'การชำระเงินเกิดข้อผิดพลาด กรุณาติดต่อฝ่ายดูแลลูกค้า',
          buttons: ['ปิด'],
          mode: "ios"
        });

        await alert.present();
        alert.onDidDismiss().then(async () => {
          await this.storage.remove('cart');
          this.emitter.emit('toggle-cart');
          await modalController.dismiss(this.name, 'confirm');
        })
      }
    },
    async checkOrder() {
      try {
        if (!this.close){
          const response = await this.axios.get(`/order/checkOrder/${this.order.id}`)
          if (response.data.result.status === 1) {
            this.close = true;
            this.loading.dismiss();
            clearInterval(this.loop_check)
            const alert = await alertController.create({
              header: 'เสร็จสิ้น',
              subHeader: 'การชำระเงินสำเร็จแล้ว',
              message: 'ท่านสามารถตรวจสอบสลากได้ที่ สลากของฉัน',
              buttons: ['ปิด'],
              mode: "ios"
            });

            await alert.present();
            alert.onDidDismiss().then(async () => {
              await this.storage.remove('cart');
              this.emitter.emit('toggle-cart');
              await modalController.dismiss(this.name, 'confirm');
            })
          }
          else if (response.data.result.status === 0) {
            this.close = true;
            this.loading.dismiss();
            clearInterval(this.loop_check)
            const alert = await alertController.create({
              header: 'ขออภัย',
              subHeader: 'การชำระเงินถูกยกเลิก กรุณาติดต่อฝ่ายดูแลลูกค้า',
              buttons: ['ปิด'],
              mode: "ios"
            });

            await alert.present();
            alert.onDidDismiss().then(async () => {
              await this.storage.remove('cart');
              this.emitter.emit('toggle-cart');
              await modalController.dismiss(this.name, 'confirm');
            })
          }
        }
      } catch (e) {
        this.close = true;
        this.loading.dismiss();
        clearInterval(this.loop_check)
        const alert = await alertController.create({
          header: 'ขออภัย',
          subHeader: 'การชำระเงินถูกยกเลิก กรุณาติดต่อฝ่ายดูแลลูกค้า',
          buttons: ['ปิด'],
          mode: "ios"
        });

        await alert.present();
        alert.onDidDismiss().then(async () => {
          await this.storage.remove('cart');
          this.emitter.emit('toggle-cart');
          await modalController.dismiss(this.name, 'confirm');
        })
      }
    },
  }
}
</script>
<style scoped>
ion-modal {
  --height: auto;
}
</style>
