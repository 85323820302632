<template>
  <ion-content class="ion-padding">
    <div content="block">
      <div class="row mb-5">
        <div class="col-12" v-for="(image,i) in images" :key="i">
          <img :src="image"/>
<!--          <img :src="`../assets/header/${images.length}.png`"-->
<!--               style="width: 7em;margin-top: -17em;">-->
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer>
    <ion-toolbar class="p-0">
      <div class="row mb-0 p-1">
        <div class="col-12">
          <button @click="closeModal" class="btn w-100 font-15 mb-0 rounded-1 text-uppercase shadow-s bg-danger color-white">
            <i class="fa fa-fw fa-times me-2"></i>ปิดการแสดงรูป
          </button>
        </div>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  modalController, IonFooter,
} from '@ionic/vue';

export default {
  name: 'ImageViewer',
  components: {
    IonFooter,
    IonContent,
  },
  props: {
    images: Array,
  },
  data() {
    return {

    }
  },
  methods : {
    async closeModal(){
      await modalController.dismiss();
    }
  }
}
</script>
