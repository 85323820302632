import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: TabsPage,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'HomePage',
                component: () => import('@/views/TabStore.vue')
            },
            {
                path: 'myLottery',
                name: 'MyLotteryPage',
                component: () => import('@/views/TabMyLottery.vue')
            },
            {
                path: 'profile',
                component: () => import('@/views/TabProfile.vue')
            },
            {
                path: 'reward',
                component: () => import('@/views/reward/index.vue')
            },
        ]
    },
    {
        path: '/lottery/search',
        component: () => import('@/views/store/Search.vue')
    },
    {
        path: '/payment',
        component: () => import('@/views/payment/Index.vue')
    },
    {
        path: '/auth/login',
        component: () => import('@/views/auth/Login.vue')
    },
    {
        path: '/auth/callback',
        component: () => import('@/views/auth/CallBack.vue')
    },
    {
        path: '/redirect/:af',
        component: () => import('@/views/redirect/af.vue')
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes
})

export default router
