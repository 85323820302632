import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Sticky Component */
import '@/assets/sticky/styles/bootstrap.css';
import '@/assets/sticky/styles/style.css';
import '@/assets/sticky/fonts/css/fontawesome-all.min.css';
import '@/assets/sticky/styles/highlights/highlight_red.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";

import mitt from 'mitt'

const emitter = mitt()

import {Storage} from '@ionic/storage';

import axios from "axios";

const storage = new Storage();

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;

import VueLazyload from 'vue-lazyload';
import VueMoment from 'moment';
import {createI18n} from 'vue-i18n';
import * as th from '@/assets/language/th.json';
import * as en from '@/assets/language/en.json';
import * as lo from '@/assets/language/lo.json';

const i18n = createI18n({
    locale: 'th',
    fallbackLocale: 'th',
    messages: {
        th: {...th},
        en: {...en},
        lo: {...lo},
    }
})

storage.create().then(async () => {
    const app = createApp(App)
        .use(IonicVue)
        .use(router);

    app.use(VueLazyload);
    app.use(i18n)

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, async function (error) {
        if (error.response.status === 401) {
            await storage.remove('auth');
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !navigator.userAgent.includes('Line')) {
                window.location.href = `line://app/${process.env.VUE_APP_LIFF_ID}`;
            }else{
                window.location.href = `https://liff.line.me/${process.env.VUE_APP_LIFF_ID}`;
            }
        }
        return Promise.reject(error);
    });
    app.config.globalProperties.emitter = emitter;
    app.config.globalProperties.storage = storage;
    app.config.globalProperties.moment = VueMoment;
    const auth = await storage.get('auth');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth?.token;
    emitter.on('login', async () => {
        const auth = await storage.get('auth');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth?.token;
    })
    app.config.globalProperties.axios = axios;

    router.isReady().then(() => {
        app.mount('#app');
    });
})
