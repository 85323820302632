<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="medium" @click="cancel">ยกเลิก</ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click="confirm" :strong="true">ส่งสลิป</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list v-if="!loaded">
      <ion-item>
        <ion-thumbnail slot="start">
          <ion-skeleton-text :animated="true" class="rounded-1"></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text :animated="true" class="w-100"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text :animated="true" class="w-100"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text :animated="true" class="w-100"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-list v-else>
      <ion-item>
        <ion-label>
          <div class="row mb-0">
            <div class="col-12 text-center">
              <div class="alert alert-success">
                <h5 class="text-success">ยอดที่ต้องโอน</h5>
                <strong class="p-0 text-success font-30">{{ numberformat(order.total) }} บาท</strong>
              </div>
            </div>
            <div class="col-3 text-center pt-2">
              <img src="https://pbs.twimg.com/profile_images/924662185929752576/9cRHWYxV_400x400.jpg"
                   class="rounded-1 w-100">
            </div>
            <div class="col-9">
              <h3>{{ payments.bank.bank.name }}</h3>
              <h3>ชื่อบัญชี : {{ payments.bank.bank_deposit_name }}</h3>
              <h3>เลขบัญชี : {{ payments.bank.bank_deposit_number }}</h3>
              <button @click="copy(payments.bank.bank_deposit_number)"
                      class="btn w-100 rounded-2 text-uppercase font-12 shadow-s bg-blue-dark"><i
                  class="fa fa-fw fa-copy me-2"></i>คัดลอกเลขบัญชี
              </button>
            </div>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>
    <h3 class="text-center mt-4">วิธีชำระเงินผ่าน เลขบัญชี</h3>
    <ul>
      <li>ตั้งค่าบัญชีรับจ่ายเงินของท่านให้ถูกต้อง</li>
      <li>ใช้บัญชีที่ท่านลงทะเบียนไว้ในการโอนเงินเท่านั้น</li>
      <li>ชำระเงินตามขั้นตอน และกดที่ ส่งสลิป ด้านบนขวามือ</li>
      <li>รอระบบตรวจสอบประมาณ 1-2 นาที</li>
    </ul>
  </ion-content>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonContent,
  modalController,
  loadingController,
  alertController,
  IonSkeletonText,
  IonThumbnail,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/vue';
import {Clipboard} from '@capacitor/clipboard';

export default {
  name: 'BankModal',
  components: {
    IonHeader,
    IonToolbar,
    IonContent,
    IonSkeletonText,
    IonThumbnail,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonLabel
  },
  props: {
    payments: Object,
    order: Object
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 1000);
  },
  data() {
    return {
      loaded: false,
      loop_check: null,
      loading: null,
      loop: 0,
      close: false
    }
  },
  methods: {
    cancel() {
      return modalController.dismiss(null, 'cancel');
    },
    async confirm() {
      await this.storage.remove('cart');
      this.emitter.emit('toggle-cart');
      this.loading = await loadingController.create({
        message: 'กำลังพาท่านไปที่หน้าส่งสลิป',
        spinner: 'circles',
        mode: 'ios'
      })

      await this.loading.present();
      setTimeout(async () => {
        await modalController.dismiss(this.name, 'oa');
      },2000)
      //
      // this.loop_check = setInterval(() => {
      //   this.checkOrder()
      //   this.loop += 1;
      // }, 10000);

      // if (this.loop >= 10) {
      //   clearInterval(this.loop_check);
      //   const alert = await alertController.create({
      //     header: 'ขออภัย',
      //     subHeader: 'การชำระเงินเกิดข้อผิดพลาด กรุณาติดต่อฝ่ายดูแลลูกค้า',
      //     buttons: ['ปิด'],
      //     mode: "ios"
      //   });
      //
      //   await alert.present();
      //   alert.onDidDismiss().then(async () => {
      //     await this.storage.remove('cart');
      //     this.emitter.emit('toggle-cart');
      //     await modalController.dismiss(this.name, 'confirm');
      //   })
      // }
    },
    async checkOrder() {
      try {
        if (!this.close){
          const response = await this.axios.get(`/order/checkOrder/${this.order.id}`)
          if (response.data.status === 1) {
            this.close = true;
            this.loading.dismiss();
            clearInterval(this.loop_check)
            const alert = await alertController.create({
              header: 'เสร็จสิ้น',
              subHeader: 'การชำระเงินสำเร็จแล้ว',
              message: 'ท่านสามารถตรวจสอบสลากได้ที่ สลากของฉัน',
              buttons: ['ปิด'],
              mode: "ios"
            });

            await alert.present();
            alert.onDidDismiss().then(async () => {
              await this.storage.remove('cart');
              this.emitter.emit('toggle-cart');
              await modalController.dismiss(this.name, 'confirm');
            })
          }
          else if (response.data.status === 0) {
            this.close = true;
            this.loading.dismiss();
            clearInterval(this.loop_check)
            const alert = await alertController.create({
              header: 'ขออภัย',
              subHeader: 'การชำระเงินถูกยกเลิก กรุณาติดต่อฝ่ายดูแลลูกค้า',
              buttons: ['ปิด'],
              mode: "ios"
            });

            await alert.present();
            alert.onDidDismiss().then(async () => {
              await this.storage.remove('cart');
              this.emitter.emit('toggle-cart');
              await modalController.dismiss(this.name, 'confirm');
            })
          }
        }
      } catch (e) {
        this.close = true;
        this.loading.dismiss();
        clearInterval(this.loop_check)
        const alert = await alertController.create({
          header: 'ขออภัย',
          subHeader: 'การชำระเงินถูกยกเลิก กรุณาติดต่อฝ่ายดูแลลูกค้า',
          buttons: ['ปิด'],
          mode: "ios"
        });

        await alert.present();
        alert.onDidDismiss().then(async () => {
          await this.storage.remove('cart');
          this.emitter.emit('toggle-cart');
          await modalController.dismiss(this.name, 'confirm');
        })
      }
    },
    async copy(text) {
      try {
        await Clipboard.write({
          string: text
        });

        const alert = await alertController.create({
          header: 'คัดลอกบัญชีสำเร็จ',
          buttons: ['ตกลง'],
          mode: "ios"
        });

        await alert.present();
      } catch (e) {
        console.log(e)
      }
    },
    numberformat(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}
</script>
<style scoped>
.block {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-modal {
  --height: auto;
}
</style>
