<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>ตรวจสอบรายการสลากฯ</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" :fullscreen="true">
      <div class="content mt-4 color-black">
        <div class="row mb-0">
          <div class="col-6 col-md-4 col-lg-3 pe-1 ps-1 mb-2" v-for="lottery in carts" :key="lottery.token">
            <div class="card card-style m-0 rounded-bottom rounded-1 border-lottery">
              <img v-lazy="lottery.image[0]" @click="openImageViewer(lottery.image)">
              <img :src="`../assets/header/${lottery.count}.png`"
                   style="width: 30%;position: absolute">
            </div>
            <button @click="removeCart(lottery)"
                    class="btn w-100 font-12 p-1 mb-0 rounded-2 text-uppercase shadow-s bg-highlight-light">
              เอาออก
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <p class="mb-0">คุณสามารถเลือกซื้อสลากฯ ได้สูงสุด 20 ชุด</p>
            <p>ต่อการทำรายการซื้อ 1 ครั้ง</p>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <div class="row mb-0 p-2">
          <div class="col-6"><h5>หมดเวลาชำระ</h5></div>
          <div class="col-6 ion-text-right"><h5 class="text-danger">{{ timerExp.min }}:{{ timerExp.sec }}</h5></div>
          <div class="col-6"><h5>จำนวน</h5></div>
          <div class="col-6 ion-text-right"><h5>{{ numberformat(total.count) }} ใบ ({{ numberformat(total.count_set) }} ชุด)</h5></div>
          <div class="col-6"><h5>บริการจัดเก็บสลาก</h5></div>
          <div class="col-6 ion-text-right"><h5>{{ numberformat(total.amount - (total.count * 80)) }} บาท</h5></div>
          <div class="col-6"><h5>ราคารวม</h5></div>
          <div class="col-6 ion-text-right"><h5>{{ numberformat(total.amount) }} บาท</h5></div>
          <div class="col-12">
            <div class="divider mb-1 mt-1"></div>
          </div>
          <div class="col-12">
            <button @click="preSend()"
                    class="btn w-100 font-15 mb-0 rounded-1 text-uppercase shadow-s bg-green-dark color-white">
              ชำระเงิน
            </button>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonFooter,
  IonBackButton,
  IonButtons, loadingController, modalController,
} from '@ionic/vue';
import PaymentPage from '@/views/payment/Index'
import ImageViewer from "@/components/ImageViewer.vue";

export default {
  name: 'CartIndex',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonFooter,
    IonBackButton,
    IonButtons
  },
  mounted() {
    this.getCart();
    this.timerExpCart();
    this.loop_exp_cd = setInterval(() => {
      this.timerExpCart()
    }, 1000)
  },
  data() {
    return {
      paymentComponent: PaymentPage,
      carts: [],
      baseAPI: process.env.VUE_APP_BACKEND_BASE_URL,
      timerExp: {
        sec: "00",
        min: "00",
        hour: "00",
        day: "00"
      },
      loop_exp_cd: null,
      total: {
        count: 0,
        count_set: 0,
        amount: 0
      }
    }
  },
  methods: {
    async timerExpCart() {
      const exp = await this.storage.get('cart_exp');
      if (exp) {
        const now = this.moment(new Date()); //todays date
        const end = this.moment(exp); // another date
        const duration = this.moment.duration(now.diff(end));

        if (duration.days() >= 0 && duration.hours() >= 0 && duration.minutes() >= 0 && duration.seconds() >= 0) {
          this.timerExp = {
            sec: "00",
            min: "00",
            hour: "00",
            day: "00"
          };

          clearInterval(this.loop_exp_cd);
          // this.$swal.fire("ยกเลิกสลาก", "สลากยกเลิกเนื่องจากหมดเวลาในการยืนยันคำสั่งซื้อแล้ว กรุณาเลือกสินค้าใหม่และยืนยันคำสั่งซื้อภายใน 15 นาที", "error")
        } else if (duration.minutes() == 5 && duration.seconds() == 0) {
          // this.$swal.fire("เหลือเวลา 5 นาทีสุดท้าย", "ใกล้หมดเวลาแล้ว กรุณากดยืนยันคำสั่งซื้อเพื่อชำระเงินก่อนหมดเวลา ", "warning")
        } else {
          this.timerExp.sec = Math.abs(duration.seconds()) < 10 ? `0${Math.abs(duration.seconds())}` : Math.abs(duration.seconds());
          this.timerExp.min = Math.abs(duration.minutes()) < 10 ? `0${Math.abs(duration.minutes())}` : Math.abs(duration.minutes());
          this.timerExp.hour = Math.abs(duration.hours()) < 10 ? `0${Math.abs(duration.hours())}` : Math.abs(duration.hours());
          this.timerExp.day = Math.abs(duration.days()) < 10 ? `0${Math.abs(duration.days())}` : Math.abs(duration.days());
        }
      }
    },
    async getCart() {
      this.carts = await this.storage.get('carts');
      this.carts.forEach((cart) => {
        this.total.count += cart.count;
        this.total.count_set += 1;
        this.total.amount += cart.count * 100;
      });
    },
    async removeCart(lottery) {
      this.carts = this.carts.filter(cart => {
        return cart.token !== lottery.token
      });


      await this.storage.set('carts', JSON.parse(JSON.stringify(this.carts)));
      this.emitter.emit('toggle-cart');
      this.emitter.emit('update-cart');
      if (this.carts.length === 0) {
        await this.storage.set('cart_exp', null);
        clearInterval(this.loop_exp_cd);
        this.timerExp = {
          sec: "00",
          min: "00",
          hour: "00",
          day: "00"
        };
      }

      this.total.count_set -= 1;
      this.total.count -= lottery.count;
      this.total.amount -= lottery.count * 100;

      const response = await this.axios.post(`/lotteries/cancel_booking`, {
        full_number: lottery.number,
      });
    },
    numberformat(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async preSend() {
      this.loading = await loadingController.create({
        message: 'กรุณารอสักครู่ กำลังเตรียมการชำระเงิน',
        spinner: 'circles',
        mode: 'ios'
      })

      await this.loading.present();
      try {
        const response = await this.axios.post('/lotteries/preSend', {
          carts: this.carts,
          gift: false
        });

        await this.storage.set('carts', []);
        await this.storage.set('cart_exp', null);
        this.emitter.emit('toggle-cart');
        this.emitter.emit('update-cart');
        // await this.storage.set('payment_id', response.data.order.id);
        await this.loading.dismiss();
        const nav = document.querySelector('ion-nav');
        nav.pop();
        this.$router.push({
          path: "/payment",
          query: {
            id: response.data.order.id
          }
        })
      } catch (e) {
        console.log(e);
        // this.$swal.fire("เกิดข้อผิดพลาด", e.data.message, "error");
      }
    },
    async openImageViewer(images){
      const modal = await modalController.create({
        component: ImageViewer,
        componentProps: {
          images: images,
          title: "รูปสลาก"
        },
        initialBreakpoint: 0.7,
        breakpoints: [0.7, 0.7],
      });

      await modal.present();

      const { data, role } = await modal.onWillDismiss();
    },
  }
}
</script>
<style scoped>
.margin-lotto-stack {
  margin-top: -40%;
}
</style>
